/* eslint-disable @typescript-eslint/no-unused-vars */
import { Download } from "@mui/icons-material";
import { Button, Card, Typography, Chip, Stack } from "@mui/material";
import { Box } from "@mui/system";

export default function FileInfo({ file }: any) {
  const { name, type, uploadedAt, updatedAt } = file;
  function handleDownloadText(): void {
    if (!file?.fullTextAnnotation?.text) return;
    const element = document.createElement("a");
    const textFile = new Blob([file?.fullTextAnnotation?.text], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(textFile);
    element.download = "file.txt";
    document.body.appendChild(element);
    element.click();
  }

  const getMetaWords = (): string[] => {
    if (!file?.fullTextAnnotation?.text) return [];
    const words: string[] = file?.fullTextAnnotation?.text
      .split(" ")
      .map((word: string) => word.trim().replace(/[(),'"،]/g, ""));
    const wordsRepeat = words.reduce((wordsRepeat, word, index) => {
      const nextWord = words[index + 1];
      if (!wordsRepeat[word]) {
        wordsRepeat[word] = 1;
      } else {
        wordsRepeat[word] = wordsRepeat[word] + 1;
      }
      if (word === "محمد" && nextWord) {
        wordsRepeat[`${word} ${words[index + 1]}`] = 2;
      }
      if ((word === "بن" || word === "ابن") && nextWord) {
        const prevWord = words[index - 1] || "";
        wordsRepeat[`${prevWord} ${word} ${nextWord}`.trim()] = 2;
      }
      return wordsRepeat;
    }, {} as any);
    const excludedWords = [
      "الیه",
      "علی",
      "وهو",
      "وهي",
      "وهم",
      "نحن",
      "ولو",
      "بها",
      "عنهم",
      "اين",
      "بين",
      "التي",
      "علی",
      "هذه",
      "بعد",
      "لها",
      "وكل",
      "وكان",
      "وكانت",
      "عليها",
      "لان",
      "الا",
      "بان",
      "على",
      "انا",
      "منهما",
      "یکون",
      "ذلك",
      "وان",
      "دون",
      "الیہ",
      "لوکان",
      "منها",
      "کان",
      "علیہ",
      "الی",
      "این",
      "لکن",
      "معه",
      "فان"
    ];

    return Object.keys(wordsRepeat).filter(
      (word) =>
        wordsRepeat[word] >= 2 &&
        word.length > 2 &&
        !excludedWords.includes(word)
    );
  };

  const renderMetaWords = (): JSX.Element[] => {
    return getMetaWords().map((word) => {
      return <Chip label={word} key={word} />;
    });
  };

  return (
    <Card sx={{ padding: "16px", width: "100%", textAlign: "right" }}>
      <Box>
        <Stack flexWrap="wrap" direction="row" spacing={1}>
          {renderMetaWords()}
        </Stack>
        <Button
          variant="contained"
          startIcon={<Download />}
          color="primary"
          onClick={handleDownloadText}
        >
          Download
        </Button>
        <Typography fontSize={20}>{file?.fullTextAnnotation?.text}</Typography>
        {/* <Box display="flex" flexDirection="row" gap={2}>
          <Avatar>{type === "PDF" ? <PictureAsPdf /> : <Image />}</Avatar>
          <Box display="flex" flexDirection="row" alignItems="baseline" gap={3}>
            <Typography variant="h4">{name}</Typography>
            <Typography variant="overline">Uploaded: {uploadedAt}</Typography>
            <Typography variant="overline">Updated: {updatedAt}</Typography>
          </Box>
        </Box>

        <Divider />
        <Box>
          <Typography>Scan Results</Typography>
          <Typography>File Meta</Typography>
          <Typography>Tags</Typography>
          <Typography>Category</Typography>
        </Box> */}
      </Box>
    </Card>
  );
}
