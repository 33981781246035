/* eslint-disable @typescript-eslint/no-unused-vars */
import { UploadFile } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  getMetadata,
} from "firebase/storage";
import {
  useRef,
  useState,
  useEffect,
  FC,
  useCallback,
  ChangeEvent,
} from "react";
import { storage } from "./firebase";
import FileList from "./Components/FileList/FileList";
import FileInfo from "./Components/FileInfo/FileInfo";
import axios from "axios";
import { FileItem } from "./Types";
import { listAll } from "firebase/storage";
import ScannedImage from "./Components/ScannedImage/ScannedImage";
import { Face } from "./Types/Face";
import { CloudStorageFile } from "./Types/File";

const App: FC = () => {
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [image, setImage] = useState<File | null>(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputEl = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<CloudStorageFile[]>([]);
  const [scanInProgress, setScanInProgress] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [results, setResults] = useState([]);
  const [faces, setFaces] = useState<Face[]>([]);
  const [includesFaceRecognition, setIncludesFaceRecognition] = useState(false);

  const loadFilesFromCloud = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      const { items } = await listAll(ref(storage, "ocr"));
      const files = await Promise.all(
        items.map(async (item) => {
          const metadata = await getMetadata(ref(storage, item.fullPath));
          const file: CloudStorageFile = {
            name: metadata.name,
            timeCreated: metadata.timeCreated,
            fullPath: metadata.fullPath,
            gsPath: item.toString(),
          };
          return file;
        })
      );
      setFiles(
        files.sort((f1, f2) => {
          const t1 = new Date(f1.timeCreated).getTime();
          const t2 = new Date(f2.timeCreated).getTime();
          return t2 - t1;
        })
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadFilesFromCloud();
  }, [loadFilesFromCloud]);

  const selectFile = () => {
    if (inputEl && inputEl.current) {
      inputEl.current.click();
    }
  };

  const onFileSelected = (event: any) => {
    if (event.target.files[0]) {
      setImage(event.nativeEvent.target.files[0]);
    }
    event.target.value = null;
  };

  useEffect(() => {
    if (image) {
      setLoading(true);
      setError(false);
      const storageRef = ref(storage, `ocr/${image.name}`);
      uploadBytes(storageRef, image)
        .then((snapshot) => {
          setImage(null);
          setLoading(false);
          // loadFileFromCloud();
          setFiles((prevState) => [
            {
              gsPath: snapshot.ref.toString(),
              fullPath: snapshot.metadata.fullPath,
              name: snapshot.metadata.name,
              timeCreated: snapshot.metadata.timeCreated
            },
            ...prevState,
          ]);
        })
        .catch((error) => {
          setImage(null);
          setLoading(false);
          setError(true);
        });
    }
  }, [image, loadFilesFromCloud]);

  const fileSelectHandler = async (file: FileItem) => {
    setScanInProgress(true);
    setFaces([]);
    const link = await getDownloadURL(ref(storage, file.fullPath));
    setSelectedImage(link);
    axios
      .post(
        "https://us-central1-oppa-research-and-development.cloudfunctions.net/scan",
        { link, path: file.gsPath, includesFaceRecognition }
      )
      .then((resp: any) => {
        const results = resp.data.results;
        setResults(results);
        setScanInProgress(false);
        setSelectedFile(resp.data.ocr);
        setFaces(resp.data.faces);
      })
      .catch((error) => {
        setScanInProgress(false);
        console.log(error);
      });
  };

  function handleChangeRecognizeFaces(e: ChangeEvent<HTMLInputElement>): void {
    const { checked } = e.target;
    setIncludesFaceRecognition(checked);
  }

  function renderFaces(): JSX.Element[] {
    return faces.map((face, i) => {
      return (
        <Typography key={i}>{`- ${face.label} ${face.confidence.toFixed(
          2
        )}%`}</Typography>
      );
    });
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
          }}
        >
          {error && (
            <Alert severity="error" sx={{ marginBottom: "20px" }}>
              Error upload file, try again later.
            </Alert>
          )}
          <Button
            disabled={loading}
            variant="outlined"
            onClick={selectFile}
            sx={{ width: "500px" }}
          >
            {loading ? <CircularProgress size={20} /> : <UploadFile />}
            <Typography sx={{ marginLeft: "10px" }}>Scan File</Typography>
          </Button>
          <FormControlLabel
            color="primary"
            label="Recognize faces"
            control={
              <Checkbox
                onChange={handleChangeRecognizeFaces}
                checked={includesFaceRecognition}
              />
            }
          />
          <input
            type="file"
            accept="image/*, application/pdf"
            onChange={onFileSelected}
            ref={inputEl}
            hidden
          />
          <Box display="flex" gap={2}>
            <FileList
              onFileSelect={fileSelectHandler}
              isLoading={loading}
              files={files}
            />
            {selectedImage && !scanInProgress ? (
              <Box
                flexDirection="column"
                sx={{ width: "100%", minHeight: "200px" }}
              >
                <ScannedImage path={selectedImage} results={results} faces={faces} />
                {selectedFile && <FileInfo file={selectedFile} />}
                {renderFaces()}
              </Box>
            ) : (
              <Card sx={{ padding: "16px", width: "100%", minHeight: "200px" }}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  {scanInProgress ? (
                    <CircularProgress />
                  ) : (
                    <Typography variant="h5">
                      Select or upload file to scan
                    </Typography>
                  )}
                </Box>
              </Card>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default App;
