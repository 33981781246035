import { useRef } from "react";
import { Box } from "@mui/system";
import * as faceapi from "face-api.js";
import { Face } from "../../Types/Face";

export default function ScannedImage({
  path,
  results,
  faces,
}: {
  path: string;
  results: any[];
  faces: Face[];
}) {
  const imgRef = useRef<HTMLImageElement>(null);
  const canvasRef = useRef(null);

  const handleDrawCanvas = (): void => {
    if (!canvasRef.current || !imgRef.current) return;

    const enhancedResults = results.map((result) => {
      const box = result.detection._box;
      const imageDims = result.detection._imageDims;
      return {
        ...result,
        detection: {
          ...result.detection,
          imageDims: {
            height: imageDims._height,
            width: imageDims._width,
          },
          box: {
            x: box._x,
            y: box._y,
            width: box._width,
            height: box._height,
          },
        },
      };
    });

    const dimensions = {
      width: imgRef.current.width,
      height: imgRef.current.height,
    };

    faceapi.matchDimensions(canvasRef.current, imgRef.current!);
    const refDrawBoxes = enhancedResults
      .map((res) => {
        const box = res.detection.box;
        const imageDims = res.detection.imageDims;
        const x = (box.x * dimensions.width) / imageDims.width;
        const y = (box.y * dimensions.height) / imageDims.height;
        const width = (box.width * dimensions.width) / imageDims.width;
        const height = (box.height * dimensions.height) / imageDims.height;
        return { x, y, width, height };
      })
      .map(
        (box, i) =>
          new faceapi.draw.DrawBox(box, {
            label: faces[i]?.label || "Unknown",
          })
      );
    refDrawBoxes.forEach((drawBox) => drawBox.draw(canvasRef.current!));
  };

  return (
    <Box position="relative">
      <img
        style={{ width: "100%", maxWidth: "800px" }}
        ref={imgRef}
        onLoad={handleDrawCanvas}
        src={path}
        alt="scanned"
      />
      <canvas
        ref={canvasRef}
        style={{ position: "absolute", top: 0, left: 0 }}
      />
    </Box>
  );
}
