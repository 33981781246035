import { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { Card, ListItemButton, Skeleton } from "@mui/material";
import { Image, PictureAsPdf } from "@mui/icons-material";
import { FileItem, FileListProps } from "../../Types";

const FileList = ({ isLoading, onFileSelect, files }: FileListProps) => {
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const randomPrimaryText = () => Math.floor(Math.random() * (400 - 250 + 1) + 300);
  const randomSecondaryText = () => Math.floor(Math.random() * (100 - 250 + 1) + 250);
  const randomLoadingElements = Array.from(Array(3).keys());

  const LoadingElement = ({ last }: { last: boolean }) => (
    <>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Skeleton variant="circular">
            <Avatar />
          </Skeleton>
        </ListItemAvatar>
        <ListItemText>
          <Skeleton variant="rectangular" width={randomPrimaryText()} height={20} />
          <Skeleton
            variant="rectangular"
            width={randomSecondaryText()}
            height={15}
            sx={{ marginTop: "10px" }}
          />
        </ListItemText>
      </ListItem>
      {!last && <Divider />}
    </>
  );

  const onItemClick = (selectedItem: FileItem) => {
    onFileSelect(selectedItem);
    setSelectedFile(selectedItem.name);
  };

  if (isLoading) {
    return (
      <Card sx={{ width: "100%", minHeight: "200px" }}>
        <List>
          {randomLoadingElements.map((el, index) => (
            <LoadingElement key={el} last={index === randomLoadingElements.length - 1} />
          ))}
        </List>
      </Card>
    );
  }

  const FileElement = (props: FileItem) => {
    const { name } = props;
    return (
      <>
        <ListItemButton onClick={() => onItemClick(props)} selected={selectedFile === props.name}>
          <ListItemAvatar>
            <Avatar>{name.includes(".pdf") ? <PictureAsPdf /> : <Image />}</Avatar>
          </ListItemAvatar>
          <ListItemText primary={name} />
        </ListItemButton>
        <Divider />
      </>
    );
  };

  return (
    <Card sx={{ width: "100%", minHeight: "200px" }}>
      <List>
        {files.map((file) => {
          return <FileElement key={file.name} {...file} />;
        })}
      </List>
    </Card>
  );
};

export default FileList;
